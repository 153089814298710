<template>
  <div class="psyc-container">
    <header-bar>
      <svg-icon
        @click="$router.back(-1)"
        icon-class="back"
        width="30px"
        height="30px"
      />
      <span class="header-title">心理咨询专业技能培训 </span>
    </header-bar>
    <nav class="nav-wrapper">
      <ul>
        <li
          v-for="item in nav_list"
          :key="item.label"
          :class="{ 'is-active': item.label === activeTab }"
          @click="handleScroll(item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </nav>
    <Banner
      :backgroundImage="bannerConfig.backgroundImage"
      :iconClass="bannerConfig.iconClass"
      :iconImg="bannerConfig.iconImg"
      :title="bannerConfig.title"
      :desc="bannerConfig.desc"
    />

    <div class="policies">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">相关政策</span>
          <!-- <span class="title-cn">RELEVANT POLICIE</span> -->
        </div>

        <div class="content">
          <div class="left">
            <div class="one">
              <div class="title">习近平总书记在十九大提出</div>
              <div class="desc">
                “加强社会心理服务体系建设，培育自尊自信、理性平和、积极向上的社会心态。”
              </div>
            </div>
            <div class="two">
              <div class="title">
                2018年底国家十部委联合发文《关于印发全国心理服务体系建设试点工作方案的通知》
              </div>
              <div class="desc">
                在“加强心理服务人才队伍建设”中提出要“发展心理健康领域社会工作专业队伍”、“培育心理咨询人员队伍”等内容。
              </div>
            </div>
          </div>
          <div class="middle">
            <img
              src="~@/assets/img/切图_web_0628/心里_相关政策.png"
              width="310px"
              height="414px"
              alt=""
            />
          </div>
          <div class="right">
            <div class="top">
              <div class="desc">
                <span>2017年7月，国务院印发</span>
                <span style="color: #35599f"
                  >《国务院关于实施健康中国行动的意见》</span
                >
                <span>，文中明确了15个主要任务，</span>
                <span style="color: #35599f">“实施心理健康促进行动”</span>
                <span>位列第五。</span>
              </div>
              <div class="desc">
                在行动目标中提到：“建立和完善心理健康教育、心理热线服务、心理评估、心理咨询、心理治疗、精神科治疗等衔接合作的心理危机干预和心理援助服务模式”。
              </div>
            </div>
            <div class="bottom">
              从政府角度，“逐步将心理健康工作人员纳入专业技术岗位设置与管理体系，畅通职业发展渠道”。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="outline">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">课程大纲</span>
          <!-- <span class="title-cn">CURRICULUM SYLLABUS</span> -->
        </div>
        <div class="content">
          <div class="item" v-for="item in outlineList" :key="item.textZH">
            <div class="top">
              <div class="text-zh">{{ item.textZH }}</div>
              <!-- <div class="text-cn">{{ item.textCN }}</div> -->
            </div>
            <div class="bottom">
              <div class="text" v-for="item in item.list" :key="item">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="teacher">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">主讲老师</span>
          <!-- <span class="title-cn">LECTURER</span> -->
        </div>
        <div class="content">
          <div class="teacher-item" v-for="item in list" :key="item.name">
            <img :src="item.pic" width="203px" height="249.84px" alt="" />
            <div class="name-zh">{{ item.name_zh }}</div>
            <!-- <div class="name-cn">{{ item.name_cn }}</div> -->

            <div class="line"></div>
            <div
              class="title"
              v-for="child in item.titles"
              :key="child"
              v-html="child"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="train">
      <div class="bm-content-container">
        <div class="left">
          <img
            src="~@/assets/img/切图_web_0628/培训方式.png"
            width="330px"
            height="313px"
            alt=""
          />
        </div>
        <div class="right">
          <div class="publicTitle">
            <span class="title-zh">培训方式</span>
            <!-- <span class="title-cn">TRAINING METHODS</span> -->
          </div>

          <div class="button">线上学习</div>
          <div class="desc">
            线上课程培训，可在app、小程序、电脑端进行学习。
          </div>
        </div>
      </div>
    </div>

    <div class="assessment">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">考核与证书</span>
          <!-- <span class="title-cn">ASSESSMENT AND CERTIFICATE</span> -->
        </div>
        <div class="content">
          <div class="left">
            <div class="item">
              <span class="line"></span>
              <span class="title">考试形式</span>

              <div class="desc">线上考试，每年三次</div>
            </div>

            <div class="item">
              <span class="line"></span>
              <span class="title">考试时间</span>

              <div class="desc">每年3、7、12月</div>
            </div>

            <div class="item">
              <span class="line"></span>
              <span class="title">考试内容</span>

              <div class="desc">考核：综合考试及论文撰写</div>
              <div class="desc">
                理论和操作技能：线上考试，学员完成全部课程学习方有资格参加
              </div>
              <div class="desc">
                论文撰写：由学员提供个人成长报告（2000字以上）
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title">证书样本</div>
            <!-- <img
              src="~@/assets/img/bg_合格证书.png"
              width="216.52px"
              height="153px"
              alt=""
            /> -->
            <el-image
              :src="`${assessmentUrl}`"
              :preview-src-list="[`${assessmentUrl}`]"
            />

            <div class="desc">
              证书由中国心理卫生协会颁发，统一编号，在项目授权网站
              www.aijiad.com
              进行查询。此证书表明持证人已经通过相关课程培训与考核，具备相应的专业知识能力、可作为能力评价、考核、用人单位聘用的参考依据。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="price">
      <div class="bm-content-container">
        <div class="price-item">
          课程指导价
          <span>4880</span>
          元/人。
        </div>
        <div class="price-desc">
          包含培训课程费。考试考务管理费，证书工本费。
        </div>
      </div>
    </div>

    <div class="answer">
      <div class="bm-content-container">
        <div class="title">心理咨询师专业技能证书培训学员常见问题解答</div>

        <div class="item" v-for="item in questionList" :key="item.title">
          <div class="item-title">{{ item.title }}</div>
          <div class="item-desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/views/pc/components/header.vue";
import NavBar from "@/views/pc/components/nav.vue";
import Banner from "@/views/pc/components/banner.vue";

import iconImg from "@/assets/img/切图_web_0628/关于aijiade.png";
import assessmentUrl from "@/assets/img/bg_合格证书240918.jpg";

import backgroundImage from "@/assets/img/bg_心理咨询.png";

import { qaList, psyTeacher } from "../data";
export default {
  components: {
    HeaderBar,
    NavBar,
    Banner,
  },
  data() {
    return {
      list: psyTeacher,
      activeTab: "项目介绍",
      nav_list: [
        { label: "项目介绍", scrollTop: 0 },
        { label: "相关政策", scrollTop: 400 },
        { label: "课程大纲", scrollTop: 1000 },
        { label: "主讲老师", scrollTop: 1554 },
        { label: "培训方式", scrollTop: 2670 },
        { label: "考核与证书", scrollTop: 3100 },
      ],
      bannerConfig: {
        backgroundImage: backgroundImage,
        iconImg: iconImg,
        title: "中国心理卫生协会心理咨询师专业技能培训项目",
        desc: {
          one: "作为落实十九大精神的两项具体部署，“健康中国行动”和“社会心理服务体系建设试点工作”都需要相当数量的能在基层从事心理健康服务的人才。中国心理卫生协会作为原人社部心理咨询师职业资格考试的技术支持单位，根据社会各界对心理咨询专业人才的需求，依托自有专家团队和师资力量，依照国家《心理咨询师国家职业标准》，采用2015、2017版国考教程体系，推出本“心理咨询师专业技能培训”项目。",
          two: "",
        },
      },
      assessmentUrl: assessmentUrl,
      questionList: qaList,
      outlineList: [
        {
          textZH: "基础知识",
          textCN: "BASIC KNOWLEDGE",
          list: ["基础心理学", "发展心理学", "变态与健康心理学", "咨询心理学"],
        },
        {
          textZH: "操作技能",
          textCN: "SKILL OPERATION",
          list: ["心理测验技能", "心理评估技能", "心理咨询技能"],
        },
        {
          textZH: "职业伦理",
          textCN: "PROFESSIONAL ETHICS",
          list: ["心理咨询职业伦理", "精神卫生法相关知识"],
        },
        {
          textZH: "心理咨询和评估",
          textCN: "COUNSELING AND EVALUATION",
          list: [
            "心理评估流程和技巧",
            "心理评估量表的使用",
            "心理评估的基本内容和方法",
          ],
        },
      ],
    };
  },
  created() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    handleScroll(item) {
      this.activeTab = item.label;

      document.documentElement.scrollTop = item.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.psyc-container {
  .header-wrapper {
    position: fixed;
    top: 0;
    z-index: 9999;

    .svg-icon {
      cursor: pointer;
    }
  }
  .header-title {
    font-size: 26px;
    color: #fff;
  }

  .nav-wrapper {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #4e4c4c;

    position: fixed;
    top: 100px;
    z-index: 9999;

    display: flex;
    justify-content: center;

    ul {
      height: 60px;
      display: flex;
      justify-content: center;

      li {
        width: 200px;
        list-style: none;
        text-align: center;
        color: #fff;
        font-size: 16px;
        cursor: pointer;

        a {
          color: #fff;
        }
      }
      .is-active {
        background: #b98862;
      }
    }
  }

  .banner {
    margin-top: 160px;
  }

  .policies,
  .outline,
  .teacher,
  .train,
  .assessment {
    width: 100%;
    background: rgba(245, 245, 245, 1);

    .content {
      margin-top: 30px;
    }
  }

  .assessment {
    height: 553px;
    .content {
      height: 366px;
      margin-top: 30px;

      display: flex;
      justify-content: space-around;

      .left {
        padding: 20px 20px 30px 20px;
        flex-basis: 527px;
        background: rgba(255, 255, 255, 1);

        .item + .item {
          margin-top: 10px;
        }
        .item {
          span {
            vertical-align: middle;
          }

          .line {
            width: 2px;
            height: 18px;
            background: rgba(185, 136, 98, 1);
            display: inline-block;
          }
          .title {
            font-size: 20px;
            font-weight: 600;
            margin-left: 10px;
          }

          .desc {
            margin-top: 10px;
            font-weight: 400;
            line-height: 22px;
            font-size: 16px;
            color: rgba(40, 40, 40, 0.9);
          }
        }
      }

      .right {
        flex-basis: 409px;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        background: url("~@/assets/img/bg_考核与证书.png");

        color: #fff;

        .title {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .desc {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }

  .policies {
    .content {
      display: flex;
      justify-content: space-between;
      .left {
        .one,
        .two {
          width: 305px;
          padding: 20px 30px;
          background: #ffffff;
          border-top: 3px solid #b98862;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #35599f;
            line-height: 22px;
          }
          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
            margin-top: 10px;
          }
        }
        .one {
          height: 158px;
        }
        .two {
          height: 236px;
          margin-top: 20px;
        }
      }

      .right {
        width: 305px;
        .top {
          height: 268px;
          padding: 20px 30px;
          border-top: 3px solid #b98862;
          background: #ffffff;

          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
          }

          .desc + .desc {
            margin-top: 10px;
          }
        }
        .bottom {
          height: 126px;
          padding: 20px 30px;
          border-top: 3px solid #b98862;
          background: #ffffff;

          margin-top: 20px;
        }
      }
    }
  }

  .outline {
    background: #fff;

    .content {
      display: flex;
      justify-content: space-around;
      .item {
        width: 225px;
        height: 378px;
        box-shadow: 0px 0px 30px 0px rgba(42, 66, 111, 0.1);

        .top {
          width: 100%;
          height: 134px;
          display: flex;
          align-items: center;
          background: #2a426f;
          padding: 30px 10px;

          .text-zh {
            font-size: 25px;
            font-weight: 600;
            color: #ffffff;
            line-height: 28px;
          }
          // .text-cn {
          //   font-size: 16px;
          //   font-weight: 500;
          //   color: #b98862;
          //   line-height: 22px;
          // }
        }
        .bottom {
          padding: 20px 30px;

          .text {
            font-size: 16px;
            font-weight: 400;
            color: #2a426f;
            line-height: 22px;
          }
          .text + .text {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .teacher {
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 10px;
      .teacher-item {
        width: 225px;
        height: 480px;
        margin-top: 20px;
        background: #b98862;
        padding: 10px;

        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        .name-zh {
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          margin-top: 10px;
          // line-height: 28px;
        }

        .name-cn {
          font-size: 16px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.4);
          // line-height: 22px;

          // position: absolute;
          // top: 263px;
        }

        .line {
          width: 20px;
          height: 1px;
          background: #fff;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .title {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }

  .train {
    height: 433px;
    background: #ffffff;

    .bm-content-container {
      display: flex;
      justify-content: space-between;

      .left {
        position: relative;
        .img-two {
          position: absolute;
          left: 20px;
          top: 20px;
        }
      }

      .right {
        flex-basis: 600px;

        .button {
          width: 120px;
          height: 44px;
          background: #b98862;

          font-size: 20px;
          font-weight: 600;
          color: #ffffff;
          line-height: 28px;

          line-height: 44px;
          text-align: center;

          margin-top: 60px;
        }

        .desc {
          font-size: 20px;
          font-weight: 500;
          color: #282828;
          line-height: 28px;

          margin-top: 30px;
        }
      }
    }
  }

  .price {
    width: 100%;
    height: 207px;

    background: #ffffff;

    .bm-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .price-item,
    .price-desc {
      font-size: 26px;
      font-weight: 400;
      color: #282828;
      line-height: 37px;
    }

    .price-item span {
      font-size: 32px;
      color: #b98862;
    }
  }

  .answer {
    padding: 60px 120px;
    background: #f5f5f5;

    .title {
      height: 88px;
      background: url("~@/assets/img/常见问题.png");
      background-size: cover;

      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 88px;
      text-align: center;
    }

    .item {
      margin-top: 30px;

      .item-title {
        font-size: 20px;
        font-weight: 600;
        color: #b98862;
        line-height: 28px;
      }
      .item-desc {
        font-size: 20px;
        font-weight: 500;
        color: #282828;
        line-height: 28px;
      }
    }
  }
  .publicTitle {
    font-size: 28px;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
    text-align: center;
    .title-zh {
      // margin-left: 112px;
    }
    // .title-cn {
    //   margin-left: 10px;
    // }
  }
}
</style>
